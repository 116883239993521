//
// Post utilities
// --------------------------------------------------

.post {
}

// Post single
// ---------------
.post-single {
	.post-body {
		color: $secondary;
	}

	.post-footer {
		@include display-flex;
		@include flex-direction(row);
		@include flex-wrap(wrap);
		@include align-items(center);
		@include justify-content(space-between);
		@include spacing(20px, 10px);
	}

	// Offsets
	* + .post-header {
		margin-top: 15px;
	}

	* + .post-meta {
		margin-top: 20px;
	}

	* + .post-body {
		margin-top: 20px;
	}

	* + .post-footer {
		margin-top: 30px;
	}

	* + .divider-fullwidth {
		margin-top: 15px;
	}
}

@include media-breakpoint-up(md) {
	.post-single {
		// Offsets
		* + .post-header {
			margin-top: 22px;
		}

		* + .post-meta {
			margin-top: 10px;
		}
	}
}

// Post info
// ---------------
.post-info {

	// Offsets
	* + .post-main {
		margin-top: 30px;
	}

	* + .post-body {
		margin-top: 20px;
	}
}

* + .post-info {
	margin-top: 40px;
}

@include media-breakpoint-up(md) {
	.post-info {
		.post-main {
			@include display-flex;
			@include flex-direction(row);
			@include flex-wrap(nowrap);
		}

		.post-left {
			@include flex-basis(1/3 * 100%);
			padding-right: 25px;
		}

		.post-body {
			@include flex-basis(2/3 * 100%);
		}

		// Offsets
		* + .post-body {
			margin-top: 0;
		}
	}
}

// Post minimal
// ---------------
.post-minimal {
	position: relative;
	border-radius: 4px;
	overflow: hidden;
	background: $white;
	box-shadow: $shadow-area-sm;

	.post-body {
		padding: 20px;
	}

	// Offsets
	* + p {
		margin-top: 8px;
	}

	* + .post-meta {
		margin-top: 5px;
	}
}

// Small <576px
@include media-breakpoint-down(xs) {
	.post-minimal {
		display: inline-block;
		width: 100%;
		max-width: 300px;
	}
}

@include media-breakpoint-up(sm) {
	.post-minimal {
		@include display-flex;
		@include flex-direction(row);
		@include flex-wrap(nowrap);
		@include align-items(center);
		@include justify-content(center);

		.post-left {
			@include display-flex;
			@include flex-direction(column);
			@include align-items(stretch);
			@include flex-shrink(0);
			@include align-self(stretch);
			width: 220px;
		}

		.post-image {
			position: relative;
			@include flex-shrink(0);
			@include align-self(stretch);
			@include flex-grow(1);
			overflow: hidden;

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				min-height: 100%;
				min-width: 100%;
				width: auto;
				height: auto;
				max-width: none;
				z-index: 1;

				@supports (object-fit: cover) {
					left: 0;
					top: 0;
					transform: none;
					object-fit: cover;
					object-position: center center;
				}
			}
		}

		.post-body {
			padding: 30px 24px 30px 27px;
			@include flex-grow(1);
		}
	}
}

@include media-breakpoint-up(xl) {
	.post-minimal {
		.post-body {
			padding: 40px 26px 40px 40px;
		}
	}
}

// Post preview
// ---------------
.post-preview {
	max-width: 320px;

	a {
		display: block;
	}

	.post-image {
		&,
		img {
			border-radius: 5px;
		}

		img {
			width: auto;
			max-width: 70px;
		}
	}

	.post-header {
		line-height: 1.5;
		color: $black;
		transition: .33s all ease;
	}

	.list-meta {
		> li {
			display: inline-block;
			font-size: 12px;
			font-style: italic;
			color: $body-color;

			&:not(:last-child) {
				&:after {
					content: '/';
				}
			}
		}
	}

	&:hover {
		.post-header {
			color: $primary;
		}
	}

	// Offsets
	* + .post-meta {
		margin-top: 5px;
	}
}

%context-dark {
	.post-preview {
		> li {
			color: rgba($white, .5);
		}

		.post-header {
			color: $white;
		}

		&:hover {
			.post-header {
				color: $primary;
			}
		}
	}
}

* + .post-preview {
	margin-top: 15px;
}

* + .post-preview-wrap {
	margin-top: 30px;
}

.post-preview-wrap-md {
	.post-preview + .post-preview {
		margin-top: 22px;
	}
}

// Post block
// ---------------
.post-block {
	position: relative;
	z-index: 1;
	@include display-flex;
	@include flex-direction(row);
	@include flex-wrap(nowrap);
	@include align-items(center);
	min-height: 200px;
	overflow: hidden;
	background: $black;

	.post-image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 0;
		opacity: .4;
		will-change: transform;
		transition: .66s all ease;

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			min-height: 100%;
			min-width: 100%;
			width: auto;
			height: auto;
			max-width: none;

			@supports (object-fit: cover) {
				left: 0;
				top: 0;
				transform: none;
				object-fit: cover;
				object-position: center center;
			}
		}
	}

	.post-header {
		line-height: 1.2;
		color: $white;

		a {
			&,
			&:active,
			&:focus {
				color: inherit;
			}

			&:hover {
				color: rgba($white, .6)
			}
		}
	}

	.post-body {
		position: relative;
		z-index: 1;
		width: 100%;
		padding: 30px 22px 30px 25px;
		pointer-events: none;

		> * {
			pointer-events: auto;
		}
	}

	.post-meta {
		@include spacing(15px, 2px);
		color: rgba($white, .5);

		> li {
			display: inline-block;
		}

		.text-style-2 {
			color: $white;
		}
	}

	&:hover {
		color: rgba($white, .8);
		.post-image {
			transform: scale(1.09);
			opacity: .2;
		}
	}

	// Offsets
	* + .post-meta {
		margin-top: 12px;
	}
}

@include media-breakpoint-up(sm) {
	.post-block {
		.post-body {
			padding: 40px 30px 40px 35px;
		}
	}
}

@include media-breakpoint-up(md) {
	.post-block {
		min-height: 250px;
		.post-body {
			padding: 60px 30px 60px 40px;
		}
	}
}

@include media-breakpoint-up(xl) {
	.post-block {
		.post-body {
			padding: 13% 8% 13% 20%;
		}

		// Offsets
		* + .post-meta {
			margin-top: 5px;
		}
	}
}

// Post modern
// ---------------
.post-modern {
	border-radius: 5px;
	overflow: hidden;
	background: $white;
	box-shadow: $shadow-area-sm;

	.post-inset {
		padding: 25px;
	}

	.post-header {
		padding: 25px;
	}

	.post-type {
		position: relative;
		top: 3px;
		font-size: 0;
		line-height: 0;
	}

	.post-list {
		@include spacing(12px, 0);

		> li {
			display: inline-block;
		}
	}

	.post-body {
		> *:only-child {
			padding-top: 0;
		}
	}

	.post-meta {
		a {
			@include link(inherit, $primary);
		}
	}

	.post-footer {
		@include display-flex;
		@include flex-direction(row);
		@include flex-wrap(nowrap);
		@include align-items(center);
		@include justify-content(space-between);
		padding: 12px 22px 16px;
		background: $gray-lighter;

		> * {
			padding: 0 7px;
		}
	}
}

* + .post-modern {
	margin-top: 30px;
}

// Post classic
// ---------------
.post-classic {
	.post-info {
		text-align: center;
		img {
			border-radius: 100%;
		}
	}

	.post-author {
		font-size: 14px;
	}

	.post-meta {
		@include spacing(20px, 2px);
	}

	.post-footer {
		padding-top: 14px;
	}

	// Offsets
	* + .post-body {
		margin-top: 24px;
	}

	* + .post-footer {
		margin-top: 20px;
	}

	* + .post-main {
		margin-top: 20px;
	}
}

.post-classic,
.post-classic-minimal {
	.post-meta {
		> li {
			display: inline-block;
		}
	}
	.post-footer {
		border-top: 1px solid $gray-light;
	}

	// Offsets
	p + p {
		margin-top: 0;
	}
}

* + .post-classic {
	margin-top: 60px;
}

@include media-breakpoint-up(md) {
	.post-classic,
	.post-classic-minimal {
		.owl-controls {
			line-height: 1;
			font-size: 0;
		}
	}

	.post-classic {
		.post-main {
			@include display-flex;
			@include flex-direction(row);
			@include flex-wrap(nowrap);
		}

		.post-info {
			@include flex-shrink(0);
			margin-left: 30px;
		}

		.post-body {
			padding-left: 40px;
		}

		.post-footer {
			padding-top: 20px;
		}

		// Offsets
		* + .post-body {
			margin-top: 0;
		}

		* + .post-main {
			margin-top: 40px;
		}
	}
}

@include media-breakpoint-up(lg) {
	.post-classic {
		.post-main {
			.post-body {
				padding-left: 60px;
			}
		}
	}

	* + .post-classic {
		margin-top: 85px;
	}
}

// Post classic minimal
.post-classic-minimal {
	time {
		font: 700 19px $font-family-serif;
		line-height: 1.2;
		color: $gunsmoke;
	}

	.post-meta {
		@include spacing(25px, 2px);

		dl {
			dt,
			dd {
				display: inline-block;
				vertical-align: baseline;
			}

			dt {
				font: 700 14px $font-family-serif;
				font-style: italic;
				color: $black;
				padding-right: 5px;
			}
		}
	}

	.post-media {
		display: inline;
	}

	.post-media,
	.post-media > * {
		max-width: 100%;
	}

	.post-footer {
		padding-top: 10px;
	}

	// Offsets
	* + .post-header {
		margin-top: 20px;
	}
	* + .post-footer {
		margin-top: 25px;
	}
	* + .post-main {
		margin-top: 15px;
	}

	.post-gallery + .post-header {
		margin-top: 22px;
	}
}

* + .post-classic-minimal {
	margin-top: 50px;
}

@include media-breakpoint-up(md) {
	.post-classic-minimal {
		@include display-flex;
		@include flex-direction(row);
		@include flex-wrap(nowrap);
		width: 100%;

		> * {
			@include flex-shrink(1);
		}

		.post-meta {
			@include spacing(45px, 2px);
		}

		.post-left {
			min-width: 70px;
		}

		.post-main {
			margin-top: -5px;
			max-width: calc(100% - 70px);
			padding-left: 30px;
			word-spacing: normal;
			white-space: normal;
		}

		// Offsets
		* + .post-header {
			margin-top: 40px;
		}

		* + .post-footer {
			margin-top: 30px;
		}

		* + .post-main {
			margin-top: 0;
		}
	}
}

@include media-breakpoint-up(lg) {
	* + .post-classic-minimal {
		margin-top: 80px;
	}
}

// Blog timeline
// ---------------
.blog-timeline {
	> dt {
		font: 900 25px $font-family-serif;
		color: $silver;
	}

	> dd {
		margin-top: 20px;
	}
}

.blog-timeline + .blog-timeline {
	margin-top: 40px;
}

@include media-breakpoint-up(md) {
	.blog-timeline + .blog-timeline {
		margin-top: 70px;
	}
}

@include media-breakpoint-up(md) {
	.blog-timeline {
		word-spacing: 0;
		white-space: nowrap;

		> * {
			display: inline-block;
		}

		> dt {
			min-width: 65px;
			padding-right: 20px;
			margin-top: -.25em;
			vertical-align: top;
		}

		> dd {
			margin-top: 0;
			width: calc(100% - 100px);
		}
	}
}

@include media-breakpoint-up(lg) {
	.blog-timeline {
		> dt {
			min-width: 100px;
			padding-right: 30px;
		}
	}
}

// Blog aside
// -----------
.blog-aside {
	@include groupY(30px);
	text-align: left;

	h6, .h6 {
		text-transform: uppercase;
	}

	.h7 + .post-preview {
		margin-top: 30px;
	}
	.h7 + .list-marked-bordered {
		margin-top: 15px;
	}
}

// Small <576px
@include media-breakpoint-down(xs) {
	.blog-aside {
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
	}
}

// Medium ≥768px
@include media-breakpoint-between(md, md) {
	.blog-aside {
		columns: 2;
		break-inside: avoid;
		-moz-column-gap: 30px;
		-webkit-column-gap: 30px;
	}

	.blog-aside-item {
		display: inline-block;
		width: 100%;
	}
}

// Large ≥992px
@include media-breakpoint-up(lg) {

	.custom-heading-line + .select2-container {
		margin-top: 42px;
	}
}

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	.blog-aside {
		padding-left: 15px;
		padding-right: 10px;
		@include groupY(50px);
	}
}

// Blog timeline
// ---------------
.post-boxed {
	.post-boxed-image {
		img {
			width: 100%;
		}
	}

	.post-boxed-title {
		font-size: 16px;
		line-height: 1.5;
		font-weight: 900;
		letter-spacing: .03em;

		a {
			display: inline;
			@include link($black, $primary);
		}
	}

	.post-boxed-body {
		padding: 16px 26px;
		border: 1px solid $porcelain;
		border-top-width: 0;
	}

	.post-boxed-meta {
		@include spacing(15px, 3px);
		font-size: 11px;
		color: $silver-chalice;
		text-transform: uppercase;

		a {
			@include link($primary, $black);
		}

		span {
			margin-right: .25em;
		}

		li {
			display: inline-block;

			&:not(:last-child) {
				position: relative;
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					@include transform(translateY(-50%));
					right: -10px;
					width: 1px;
					height: 12px;
					background: $gallery;
				}
			}
		}
	}

	// Offsets
	* + .post-boxed-footer {
		margin-top: 9px;
	}
}
@charset "UTF-8";
//
// Imports
//

@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "variables_custom";

@import "custom-styles/mixins-custom";

// Additional Style Reset
@import "custom-styles/reset";

// Toolkit Components
@import "custom-styles/scaffolding-custom";
@import "custom-styles/type-custom.scss";
@import "custom-styles/alerts-custom.scss";
@import "custom-styles/text-styling";
@import "custom-styles/buttons-custom";
@import "custom-styles/icons";
// @import "custom-styles/font-awesome";
// @import "custom-styles/material-icons";
// @import "custom-styles/mercury";
// @import "custom-styles/materialdesignicons";
// @import "custom-styles/fl-flat-icons-set-2";
// @import "custom-styles/fl-bigmug-line";
// @import "custom-styles/fl-36-slim-icons";
@import "custom-styles/thumbnails-custom";
@import "custom-styles/figures-custom";
@import "custom-styles/forms-custom";
@import "custom-styles/unit-responsive";
@import "custom-styles/stacktable";
@import "custom-styles/sections";
@import "custom-styles/groups";
@import "custom-styles/utilities-custom";
@import "custom-styles/breadcrumbs-custom";
@import "custom-styles/pagination-custom";
@import "custom-styles/label-custom";
@import "custom-styles/quotes-custom";
@import "custom-styles/box-custom";
@import "custom-styles/post-utilities";
@import "custom-styles/comments-utilities";
@import "custom-styles/page-title";
@import "custom-styles/page-loaders";
@import "custom-styles/shop-utilities";
@import "custom-styles/one-screen-page";
@import "custom-styles/offsets";
@import "custom-styles/links";
@import "custom-styles/lists";
@import "custom-styles/wrappers";
@import "custom-styles/dividers";
@import "custom-styles/nav-custom";
@import "custom-styles/tables-custom";
@import "custom-styles/card-custom";
@import "custom-styles/grid-modules";

/*
 * Custom Plugins
 */
@import "custom-styles/plugins/animate";
@import "custom-styles/plugins/counter";
@import "custom-styles/plugins/countdown";
@import "custom-styles/plugins/isotope";
@import "custom-styles/plugins/owl-carousel";
@import "custom-styles/plugins/rd-navbar";
@import "custom-styles/plugins/material-parallax";
@import "custom-styles/plugins/rd-google-map";
@import "custom-styles/plugins/rd-search";
@import "custom-styles/plugins/rd-twitter";
// @import "custom-styles/plugins/swiper";
@import "custom-styles/plugins/ui-to-top";
@import "custom-styles/plugins/progress-bar";
@import "custom-styles/plugins/timecircles";
@import "custom-styles/plugins/slick";
@import "custom-styles/plugins/jquery.fs.stepper";
@import "custom-styles/plugins/bootstrap-material-datetimepicker";
@import "custom-styles/plugins/select2";
@import "custom-styles/plugins/magnific-popup";
@import "custom-styles/plugins/soundcloud";
@import "custom-styles/plugins/scrollspy";
@import "custom-styles/plugins/lightgallery";
@import "custom-styles/plugins/lg-transitions";



[data-x-mode='true'] {
	.preloader {
		display: none;
		visibility: hidden;
		opacity: 0;
	}

	.section-single-header .divider-wide {
		top: 20px;
	}

}

.novi-bg-img-custom,
.novi-bg-img {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

//
// Indent Utilities
// --------------------------------------------------

@mixin indent-responsive($prefix, $postfix, $rules, $breakpoints, $offsets) {
  @if ($postfix != '' and $postfix != null) {
    $postfix: #{$postfix}-;
  }

  @if ($prefix != '' and $prefix != null) {
    $prefix: #{$prefix};
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint) {

      @each $offset in $offsets {
        .#{$prefix}#{$infix}-#{$postfix}#{strip-unit($offset)} {
          @each $rule in $rules {
            #{$rule}: $offset;
          }
        }
      }
    }
  }
}



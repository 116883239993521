/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
	-webkit-animation-duration: 1000ms;
	animation-duration: 1000ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
	display: none;
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-text-select-on .owl-item {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel .owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	-webkit-transition: opacity 400ms ease;
	-moz-transition: opacity 400ms ease;
	-ms-transition: opacity 400ms ease;
	-o-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	font: 400 40px/80px 'FontAwesome';
	cursor: pointer;
	z-index: 1;
	-webkit-transition: scale 100ms ease;
	-moz-transition: scale 100ms ease;
	-ms-transition: scale 100ms ease;
	-o-transition: scale 100ms ease;
	transition: scale 100ms ease;
	&:before {
		content: '\f144';
	}
}

.owl-carousel .owl-video-play-icon:hover {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-background-size: contain;
	-moz-background-size: contain;
	-o-background-size: contain;
	background-size: contain;
	-webkit-transition: opacity 400ms ease;
	-moz-transition: opacity 400ms ease;
	-ms-transition: opacity 400ms ease;
	-o-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
}

// Owl carousel styles
// ------------------------
.owl-carousel {
	.owl-stage {
		@include display-flex;
		@include flex-direction(row);
		@include flex-wrap(nowrap);
		@include align-items(stretch);
	}
	.owl-item {
		float: none;
		@include display-inline-flex;
		@include flex-grow(1);
		@include flex-shrink(0);
		@include align-items(stretch);
	}

	.item {
		width: 100%;
	}
}

.owl-spacing-1 {
	@include media-breakpoint-up(lg) {
		padding-right: 60px;
		padding-left: 60px;
	}
	@include media-breakpoint-up(xl) {
		.owl-item {
			padding-right: 41px;
			padding-left: 41px;
		}

		.owl-prev {
			left: 0%;
		}

		.owl-next {
			right: 0%;
		}
	}

	@media (min-width: 1400px) {
		padding: 0;

		.owl-prev {
			left: -6%;
		}

		.owl-next {
			right: -6%;
		}
	}
}

/*
 * Owl Navigation
 */

.owl-nav {
	&.disabled {
		display: none;
	}
}

.owl-prev,
.owl-next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font: 400 24px 'Material Icons';
	color: $ebony-clay;
	transition: .22s;
	cursor: pointer;

	&:hover {
		color: $primary;
	}
}

.owl-prev {
	left: 0;
	&:before {
		content: '\e5c4';
	}
}

.owl-next {
	right: 0;
	&:before {
		content: '\e5c8';
	}
}

/*
 * Owl Pagination
 */

.owl-dots {
	text-align: center;

	&.disabled {
		display: none;
	}
}

.owl-dot {
	display: inline-block;
}

/*
 * Owl Pagination
 */
.owl-numbering-default {
	padding-bottom: 15px;
	> * {
		display: inline-block;
	}

	.numbering-current,
	.numbering-count {
		font-weight: 700;
	}

	.numbering-current {
		min-width: 16px;
		font-size: 25px;
		color: $black;
		transition: .33s all ease;
	}

	.numbering-separator {
		position: relative;
		display: inline-block;
		margin: 0 10px;

		&:after {
			position: absolute;
			top: -23px;
			left: -6px;
			content: '';
			width: 2px;
			height: 51px;
			transform-origin: 50% 75%;
			transform: rotate(30deg);
			background: rgba($black, .3);
		}
	}

	.numbering-count {
		position: relative;
		top: 19px;
		left: -2px;
		font-size: 18px;
		color: rgba($black, .3);
	}

	// Mods
	&.owl-numbering-inverse {
	}
}

// Styles
.owl-carousel-inverse {
	.owl-next,
	.owl-prev {
		color: $white;

		&:hover {
			color: $primary;
		}
	}

	.owl-numbering-default {
		.numbering-current {
			color: $white;
		}

		.numbering-separator:after {
			background: rgba($white, .3);
		}

		.numbering-count {
			color: rgba($white, .3);
		}
	}
}

.owl-nav-position-numbering {
	$offset: 42px;
	.owl-next,
	.owl-prev {
		top: auto;
		bottom: -53px;
		transform: none;
	}

	.owl-prev {
		left: auto;
		right: calc(50% + #{$offset});
	}

	.owl-next {
		right: auto;
		left: calc(50% + #{$offset});
	}

	& + .owl-numbering {
		margin-top: 15px;
	}
}

.owl-nav-bottom-left {
	.owl-nav {
		margin-top: 15px;
	}

	.owl-next,
	.owl-prev {
		display: inline-block;
		position: static;
		top: auto;
		transform: none;
	}

	.owl-prev {
		left: auto;
	}

	.owl-next {
		right: auto;
		margin-left: 10px;
	}
}

.owl-style-minimal {
	.item {
		width: 100%;

		img {
			width: 100%;
		}
	}

	.owl-dots {
		margin-top: 10px;
		text-align: center;
	}

	.owl-dot {
		cursor: pointer;
		width: 16px;
		height: 16px;
		border-radius: 10px;
		background: $alto;
		transition: .33s all ease;
	}

	.owl-dot.active,
	.owl-dot:hover {
		background: $primary;
	}

	// Offsets
	.owl-dot + .owl-dot {
		margin-left: 16px;
	}
}

.owl-nav-classic {
	.owl-nav {
		display: none;
	}

	@include media-breakpoint-up(lg) {
		.owl-dots {
			display: none !important;
		}

		.owl-nav {
			display: block;

			.owl-prev,
			.owl-next {
				top: 45%;
				transform: translateY(-45%);
				width: 45px;
				height: 45px;
				line-height: 45px;
				color: $dusty-gray;
				background: $whisper;
				text-align: center;
				font: 400 20px/45px 'fl-flat-icons-set-2';

				&:hover {
					color: $white;
					background: $primary;
				}
			}

			.owl-prev {
				padding-right: 3px;
				&:before {
					position: relative;
					display: inline-block;
					content: '\e015';
					transform: scale(-1, 1);
				}
			}

			.owl-next {
				padding-left: 3px;
				&:before {
					content: '\e015';
				}
			}
		}
	}
}
//
// Custom utilities
// --------------------------------------------------

// Helpers
// -------------------------
.relative {
	position: relative;
}

.static {
	position: static;
}

.block-top-level {
	position: relative;
	z-index: 3;
}

.height-fill {
	@include display-flex;
	@include flex-direction(column);
	@include align-items(stretch);

	> * {
		@include flex-grow(1);
	}
}

.centered {
	@include display-flex;
	@include justify-content(center);
	@include align-items(center);
}

.align-bottom {
	@include align-self(flex-end);
}

.block-centered {
	margin-left: auto;
	margin-right: auto;
}

.responsive-centered {
	@include media-breakpoint-down(xs) {
		margin-left: auto;
		margin-right: auto;
	}
}

.overflow-hidden {
	overflow: hidden;
}

.site-main .white-space-normal {
	white-space: normal;
}

// Main Layout
// -------------------------
* + h1,
* + .h1 {
	margin-top: 10px;
}

* + h2,
* + .h2 {
	margin-top: 1px;
}

* + h3,
* + .h3 {
	margin-top: 20px;

	@include media-breakpoint-up(md) {
		margin-top: 27px;
	}

	@include media-breakpoint-up(lg) {
		margin-top: 34px;
	}
}

* + h4,
* + .h4 {
	margin-top: 25px;
}

* + h5,
* + .h5 {
	margin-top: 10px;
}

* + h6,
* + .h6 {
	margin-top: 15px;
}

* + p,
* + .p {
	margin-top: 14px;
}

h1 + *,
.h1 + * {
	margin-top: 20px;
}

h2 + *,
.h2 + * {
	margin-top: 15px;
}

h3 + *,
.h3 + * {
	margin-top: 23px;

	@include media-breakpoint-up(md) {
		margin-top: 36px;
	}
}

h4 + *,
.h4 + * {
	margin-top: 25px;
}

h5 + *,
.h5 + * {
	margin-top: 18px;
}

h6 + *,
.h6 + * {
	margin-top: 14px;
}

hr + * {
	margin-top: 18px;
	@include media-breakpoint-up(xl) {
		margin-top: 26px;
	}
}

p + p {
	margin-top: 27px;
}

* + .big {
	margin-top: 6px;
}

* + .text-large {
	margin-top: 10px;
}

* + .text-bigger {
	margin-top: 19px;

	@include media-breakpoint-up(md) {
		margin-top: 10px;
	}
}

* + .btn {
	margin-top: 30px;

	@include media-breakpoint-up(xl) {
		margin-top: 44px;
	}
}

* + .link {
	margin-top: 18px;
}

* + .contact-info {
	margin-top: 16px;
}

* + .list-inline {
	margin-top: 32px;
}

* + .list-terms {
	margin-top: 42px;

	@include media-breakpoint-up(xl) {
		margin-top: 62px;
	}
}

* + .list-marked,
* + .list-ordered {
	margin-top: 22px;
}

* + .link-wrap {
	margin-top: 8px;
}

* + .link-iconed {
	margin-top: 2px;
}

// Misc
// -------------------------
.contact-info {
	color: $secondary;
	vertical-align: baseline;

	a {
		display: inline-block;
	}

	dl {
		dt, dd {
			display: inline-block;
		}

		dt:after {
			content: ':';
			display: inline-block;
			text-align: center;
		}
	}

	.dl-inline {
		dt {
			padding-right: 0;
		}
	}
}

.grid-system {
	p {
		color: $secondary;
	}
	@include media-breakpoint-down(lg) {
		p {
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.object-inline,
.object-inline-baseline {
	white-space: nowrap;
	> * + * {
		margin-top: 0;
		margin-left: 5px;
	}
}

.object-inline {
	vertical-align: middle;
	> * {
		display: inline-block;
		vertical-align: middle;
	}
}

.object-inline-baseline {
	vertical-align: baseline;

	> * {
		display: inline-block;
		vertical-align: baseline;
	}
}

.row-no-gutter {
	margin-left: 0;
	margin-right: 0;

	[class*='col'] {
		padding: 0;
	}
}

.text-width-1 {
	max-width: 400px;
	@include media-breakpoint-up(lg) {
		max-width: 310px;
	}
}

.min-width-1 {
	min-width: 100%;
	@include media-breakpoint-up(sm) {
		min-width: 270px;
	}
}

.img-shadow {
	box-shadow: -3px 2px 4px 0px rgba($black, 0.58);
	@include media-breakpoint-up(md) {
		box-shadow: -5px 4px 8px 0px rgba($black, 0.58);
	}
}

.bg-cover {
	background-position: top center;
	-webkit-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
}

/*
* @subsection   Google Map
* @description  Describes style declarations for RD Google Map extension
* @version      1.0.0
*/
.google-map-markers {
	display: none;
}

.google-map-container {
	width: 100%;
}

.google-map {
	height: 200px;
	width: 100%;
	border: 1px solid #eaeaea;
	color: #333;
}

@media (min-width: 480px) {
	.google-map {
		height: 250px;
	}
}

@media (min-width: 768px) {
	.google-map {
		height: 532px;
	}
}

@media (min-width: 992px) {
	html:not([data-x-mode='true']) .google-map-container-2 {
		height: 100%;
		.google-map {
			height: 100%;
		}
	}
}
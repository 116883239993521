//
// Custom form styles
// --------------------------------------------------

$form-inline-border-radius: 5px;

.rd-mailform {
	position: relative;
}

// Normalize non-controls
// --------------------------------------------------

label {
	margin-bottom: 0;
}

input:not(:empty) + .form-label,
input:-webkit-autofill + .form-label {
	display: none;
	color: transparent;
}

// Common form styles
// --------------------------------------------------
.form-label,
.form-input {
	font-weight: 400;
}

// Common form controls
// --------------------------------------------------

.input-sm,
.input-lg,
.form-input {
	font-size: $form-input-font-size;

	&,
	&:focus {
		box-shadow: none;
	}
}

textarea.form-input {
	height: $form-textarea-default-height;
	min-height: $form-textarea-default-min-height;
	max-height: $form-textarea-default-max-height;
	resize: vertical;
}

.form-input {
	width: 100%;
	height: auto;
	min-height: $form-input-height;
	padding: $form-input-padding;
	border: $form-input-border;
	border-radius: 0;
	-webkit-appearance: none;
	line-height: $form-input-line-height;

	&:focus {
		outline: 0;
	}
	.bg-white & {
		background-color: $gray-lighter;
	}
}

// Form groups
// -------------------------

.form-wrap {
	position: relative;
	margin-bottom: 0;

	& + .form-wrap {
		margin-top: 10px;
	}
}

// Form labels
// -------------------------

.form-label {
	position: absolute;
	top: 26px;
	left: $form-input-padding-horizontal;
	font-size: $form-input-font-size;
	color: $form-input-color-placeholder;
	pointer-events: none;
	z-index: 9;
	transition: .3s;
	transform: translateY(-50%);
	will-change: transform;

	&.focus {
		opacity: 0;
	}

	&.auto-fill {
		color: $form-input-color;
	}
}

[data-x-mode='true'] {
	.form-label {
		pointer-events: auto;
	}
}

.form-label-outside {
	@include media-breakpoint-up(md) {
		position: static;
		margin-bottom: 8px;

		&, &.focus, &.auto-fill {
			transform: none;
			color: $form-input-color-placeholder;
			font-size: $form-input-font-size;
		}
	}
}

// Form validation
// -------------------------
.form-validation {
	position: absolute;
	right: 10px;
	top: 2px;
	font-size: 11px;
	line-height: 11px;
	color: $brand-danger;
	margin-top: 2px;
	transition: .3s;
}

form.label-outside {
	.form-validation {
		top: 7px;
	}
}

// Error Styling
//
.has-error {
	@include form-control-validation-custom($state-danger-text, $state-danger-bg, $state-danger-bg);
}

// Success Styling
//
.has-success {
	@include form-control-validation-custom($state-success-text, $state-success-bg, $state-success-bg);
}

// Form output
// -------------------------

#form-output-global {
	position: fixed;
	bottom: 30px;
	left: 15px;
	visibility: hidden;
	transform: translateX(-500px);
	transition: .3s all ease;
	z-index: 9999999;

	&.active {
		transform: translateX(0);
		visibility: visible;
	}

	@include media-breakpoint-up(sm) {
		left: 30px;
	}
}

.form-output {
	position: absolute;
	top: 100%;
	left: 0;
	font-size: 14px;
	line-height: 1.5;
	margin-top: 2px;
	transition: .3s;
	opacity: 0;
	visibility: hidden;

	&.active {
		opacity: 1;
		visibility: visible;
	}
	&.error {
		color: $brand-danger;
	}
	&.success {
		color: $brand-success;
	}
}

// Radio and Checkbox Custom
// -------------------------

// Base Styles
//
.radio .radio-custom,
.radio-inline .radio-custom,
.checkbox .checkbox-custom,
.checkbox-inline .checkbox-custom {
	opacity: 0;

	&, &-dummy {
		position: absolute;
		width: 18px;
		height: 18px;
		margin-left: -20px;
		margin-top: 3px;
		outline: none;
		cursor: pointer;
	}

	&-dummy {
		pointer-events: none;

		&:after {
			position: absolute;
			opacity: 0;
			transition: .22s;
		}
	}

	&:focus {
		outline: none;
	}
}

.radio-custom:checked + .radio-custom-dummy:after,
.checkbox-custom:checked + .checkbox-custom-dummy:after {
	opacity: 1;
}

// Custom Radio Styles
//
.radio,
.radio-inline {
	padding-left: 30px;
	.radio-custom-dummy {
		margin-top: 2px;
		border-radius: 50%;
		margin-left: -30px;
		background: transparent;
		border: 2px solid $black;

		&:after {
			content: '';
			top: 3px;
			right: 3px;
			bottom: 3px;
			left: 3px;
			background: $gray-darker;
			border-radius: 50%;
		}
	}
}

.form-wrap-color,
.form-wrap-size {
	.radio-inline {
		padding-left: 0;
	}

	.radio-control {
		position: relative;
		display: block;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		margin-top: 23px;
		margin-bottom: 23px;

		&:after {
			bottom: 0;
		}

		&:after {
			content: '';
			position: absolute;
			left: 50%;
			bottom: -23px;
			transform: translateX(-50%);
			width: 0;
			max-width: 100%;
			height: 3px;
			background: $primary;
			visibility: hidden;
			transition: .2s;
		}
	}

	.radio-custom:checked ~ .radio-control {
		&:after {
			visibility: visible;
			width: 100%;
		}
	}

	.radio-custom-dummy {
		display: none;
	}
}

.form-wrap-size {
	.radio-inline {
		padding-left: 2px;
		padding-right: 2px;
	}
	.radio-inline + .radio-inline {
		margin-left: 1px;
	}

	.radio-control {
		color: $body-color;
		text-align: center;
		text-transform: uppercase;
		transition: .2s;

		&:hover {
			color: $black;
		}
	}

	.radio-custom:checked ~ .radio-control {
		color: $black;
	}
}

// Custom Checkbox Styles
//
.checkbox,
.checkbox-inline {
	padding-left: 38px;
	color: $black;
	.checkbox-custom-dummy {
		pointer-events: none;
		border-radius: 2px;
		margin-left: 0;
		left: 0;
		background: $white;
		box-shadow: none;
		border: 2px solid $gray-light;

		&:after {
			content: '\e5ca';
			font-family: 'Material Icons';
			font-size: 22px;
			line-height: 10px;
			position: absolute;
			top: 0;
			left: -1px;
			color: $gray-dark;
		}
	}
}

.checkbox-small {
	padding-left: 26px;
	.checkbox-custom-dummy {
		margin-top: 6px;
		width: 12px;
		height: 12px;
		border-width: 1px;
		border-radius: 1px;

		&:after {
			top: -1px;
			left: -2px;
			font-size: 18px;
		}
	}
}

// Custom styles
.textarea-lined-wrap {
	position: relative;
	line-height: 2.39;
	background: url('../images/textarea-pattern-light.png') repeat;

	textarea {
		height: 204px;
		resize: none;
		overflow: hidden;
		line-height: 2.39;
		background-color: transparent;
	}
}

.textarea-lined-wrap-xs {
	textarea {
		height: 68px;
	}
}

.site-main{
	.form-classic-bordered {
		.form-label,
		.form-label-outside,
		.form-input {
			color: $black;
		}

		.form-input {
			border: 1px solid $gray-light;
		}
	}

	.form-modern {
		.form-input,
		.form-label {
			color: $body-color;
		}

		input {
			height: auto;
			min-height: 20px;
		}

		.form-input:focus {
			border-color: $primary;
		}

		.form-input {
			padding: 6px 0;
			border-radius: 0;
			border-width: 0 0 1px 0;
			border-color: $gray-light;
			background-color: transparent;
		}

		.form-label {
			left: 0;
			top: 18px;
		}

		.form-validation {
			top: auto;
			left: auto;
			right: 0;
			bottom: -12px;
			font-style: italic;
		}

		.textarea-lined-wrap {
			.form-validation {
				bottom: -5px;
			}
		}

		.form-wrap + .form-wrap {
			margin-top: 22px;
		}
		* + .button {
			margin-top: 30px;
		}

		.has-error {
			@include form-control-validation-custom($state-danger-text, $state-danger-bg, $state-danger-bg);
		}

		// Colors
		&.form-darker {
			.form-input,
			.form-label {
				color: $black;
			}

			.form-label:not(.focus) + .form-input {
				border-color: $silver;
			}
		}
	}
}

%context-dark {
	.form-classic-bordered {
		.form-label,
		.form-input {
			color: $body-color;
			background-color: transparent;
		}
	}
}

.form-classic {
	&.form-inline {
		@include display-flex;
		@include flex-direction(row);
		@include flex-wrap(nowrap);
		@include align-items(stretch);
		@include justify-content(center);

		.form-wrap {
			@include flex-grow(1);
			margin-right: -5px;
		}

		.form-input {
			width: 100%;
			border-radius: $form-inline-border-radius 0 0 $form-inline-border-radius;
		}

		.button {
			position: relative;
			z-index: 2;
			@include flex-shrink(0);
			border-radius: 0 $form-inline-border-radius $form-inline-border-radius 0;
		}

		// Error style
		.has-error {
			& + .form-input {
				border: 1px solid $form-input-background;
			}

			@include form-control-validation-custom($state-danger-text, $state-danger-bg, $state-danger-bg);
		}

		.form-validation {
			font-style: italic;
			top: auto;
			right: auto;
			left: 0;
			bottom: -15px;
		}

		// Offsets
		> * + * {
			margin-top: 0;
		}
	}
}

.form-classic-wrap {
	// Medium ≥768px
	@include media-breakpoint-up(md) {
		padding-right: 60px;
	}

	// Large ≥992px
	@include media-breakpoint-up(lg) {
		padding-right: 30px;
	}

	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		padding-right: 100px;
	}

	.form-wrap + .form-wrap {
		margin-top: 20px;
	}
}

* + .form-classic-wrap {
	margin-top: 15px;
}

.mailform-wrap {
	max-width: 370px;
	margin-left: auto;
	margin-right: auto;
}

* + .mailform-wrap {
	margin-top: 30px;
}
.counter{
  margin-bottom: 0;
}

.counter-k {
  &:after {
    content: 'k';
  }
}

.counter-percent {
  &:after {
    display: inline-block;
    content: '%';
    font-size: .33em;
    vertical-align: middle;
    margin-top: -1.6em;
    margin-left: .25em;
  }

  @include media-breakpoint-up(lg) {
    &:after {
      margin-top: -1.9em;
    }
  }
}
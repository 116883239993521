//
// Pagination
// --------------------------------------------------
$pagination-size: 52px;
$pagination-offset: 20px;

.pagination-custom {
  position: relative;
  display: inline-block;
  @include spacing(8px);
  line-height: 0;
  font-size: 0;

  li {
    display: inline-block;
    vertical-align: middle;
  }

  li a {
    display: block;
    width: auto;
    min-height: $pagination-size;
    min-width: $pagination-size;
    height: $pagination-size;
    padding: 10px 20px;
    border: 2px solid;
    font: 700 12px/24px $font-family-base;
    vertical-align: middle;

    &:after {
      content: '';
      height: 108%;
      width: 0;
      display: inline-block;
      vertical-align: middle;
    }

    &,
    &:active,
    &:focus {
      color: $pagination-color;
      background: $pagination-bg;
      border-color: $pagination-border;
    }

    &:hover {
      color: $pagination-hover-color;
      background: $pagination-hover-bg;
      border-color: $pagination-hover-border;
    }
  }

  li:first-child,
  li:last-child {
    a {
      padding: 10px 29px;
      font-size: 17px;
    }
  }

  li.disabled,
  li.active {
    pointer-events: none;

    [data-x-mode="true"] & {
      pointer-events: auto;
    }
  }

  li.active a {
    color: $pagination-active-color;
    background: $pagination-active-bg;
    border-color: $pagination-active-border;
  }

  li.disabled a {
    color: $pagination-disabled-color;
    background: $pagination-disabled-bg;
    border-color: $pagination-disabled-border;
  }
}


* + .pagination-custom,
* + .pagination-custom-wrap {
  margin-top: 40px;

  // Medium ≥768px
  @include media-breakpoint-up(md) {
    margin-top: 60px;
  }

  // Extra large ≥1200px
  @include media-breakpoint-up(xl) {
    margin-top: 100px;
  }
}
//
// Scaffolding
// --------------------------------------------------

// Reset the box-sizing
//
// Heads up! This reset may cause conflicts with some third-party widgets.
// For recommendations on resolving such conflicts, see
// http://getbootstrap.com/getting-started/#third-box-sizing
* {
	box-sizing: border-box;
}

*:before,
*:after {
	box-sizing: border-box;
}

// Body reset

html {
	font-size: 10px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
	@extend .font-size-default;
	font-family: $font-family-base;
	-webkit-text-size-adjust: none;
	color: $body-color;
	background-color: $body-bg;
	font-weight: $font-weight-base;
	-webkit-font-smoothing: subpixel-antialiased;
	text-rendering: optimizeLegibility;
}

.site-main {
	overflow-x: hidden;
}

/*
* @section      Page Header
* @description  This section holds specific style redeclarations for some
*               of common elements in page header
*/

.page-head {
	position: relative;
	z-index: 1100;
	background-color: $header-bg;
}

/*
* @section      Page Content
* @description  This section holds specific style redeclarations for some
*               of common elements in page content
*/

section {
	position: relative;
	z-index: 1;
}

.page-content {
	position: relative;
	z-index: 1;
}

/*
* @section      Page Footer
* @description  This section holds specific style redeclarations for some
*               of common elements in page footer
*/

.page-foot {
	background-color: $footer-bg;

	.h7 + * {
		margin-top: 22px;
	}
	.unit + .unit {
		margin-top: 15px;
	}
	.post-preview + .post-preview {
		margin-top: 22px;
	}
	.brand + p {
		margin-top: 22px;
	}

	// Large ≥992px
	@include media-breakpoint-up(lg) {
		.h7 + * {
			margin-top: 30px;
		}
	}



}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

// Links

a {
	display: inline-block;
	text-decoration: none;
	transition: .33s all ease-out;

	&, &:active, &:focus {
		color: $link-color;
	}

	&:hover,
	&:focus {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}

	&:focus {
		outline: 0;
	}

	&[href*='tel'],
	&[href*='mailto'] {
		white-space: nowrap;
	}
}

// Figures
//
// We reset this here because previously Normalize had no `figure` margins. This
// ensures we don't break anyone's use of the element.

figure {
	margin: 0;
}

// Images

img {
	vertical-align: middle;
	max-width: 100%;
	height: auto;
}

// Responsive images (ensure images don't scale beyond their parents)
.img-responsive {
	@include img-responsive;
}

// Rounded corners
.img-rounded {
	border-radius: $border-radius-large;
}

// Image thumbnails
//
// Heads up! This is mixin-ed into thumbnails.less for `.thumbnail`.
.img-thumbnail {
	padding: $thumbnail-padding;
	line-height: $line-height-base;
	background-color: $thumbnail-bg;
	border: 1px solid $thumbnail-border;
	border-radius: $thumbnail-border-radius;
	@include transition(all .2s ease-in-out);

	// Keep them at most 100% wide
	@include img-responsive(inline-block);
}

// Perfect circle
.img-circle {
	border-radius: 50%; // set radius in percents
}

// Horizontal rules

hr {
	margin-top: 0;
	margin-bottom: 0;
	border: 0;
	border-top: 1px solid $hr-border;
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}
}

// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
	cursor: pointer;
}

.brand {
	display: inline-block;
	white-space: nowrap;
	/**
		@bugfix: color flickering in child objects on hover
		@affected: IE Edge
	*/
	transition: none !important;

	> * {
		display: inline-block;
		vertical-align: middle;
		margin: 0;
	}
}

.rights {
	display: inline-block;
	margin: 0;
	line-height: 1.5;
	letter-spacing: .025em;
	vertical-align: baseline;

	* {
		display: inline;
		margin-right: .25em;
	}
}

.page-foot-default {
	.rights {
		font-weight: 300;
	}
}

.privacy-link {
	margin-top: 30px;
}
/*
*
* Grid Modules
*/

@mixin make-grid-columns-custom($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      @for $i from 1 through $columns {
        > .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }
    }
  }
}

.row-seven {
  @include make-grid-columns-custom(7);
}

.flex-row-md-reverse {
  // Medium ≥768px
  @include media-breakpoint-up(md) {
    flex-direction: row-reverse; 
  }
}